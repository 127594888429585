<template>
<div>

    <div class="text-center mt-5 " v-if="cargando">
        <div class="spinner-border text-primary spinner-border-sm" role="status">
            <span class="sr-only">Loading...</span>
        </div>
        <br>
        Cargando ...
    </div>

    <div class="row" v-if="!cargando">

        <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
                <li class="breadcrumb-item"><a href="/clases" ><i class="fas fa-arrow-left me-1"></i> Regresar a las Clases</a></li>
                <li class="breadcrumb-item active" aria-current="page">  <strong>Actividad:</strong> {{infoclase.titulos}} </li>
            </ol>
        </nav>

        <div class="mb-3">
            <button @click="editarclase" class="btn btn-primary btn-sm me-2">  <i class="far fa-edit"></i> Editar Clase </button>
            <button class="btn btn-primary btn-sm" data-bs-toggle="modal" data-bs-target="#exampleModal"> <i class="fas fa-plus"></i> Agregar Archivos</button>
        </div>

        <div class="col-md-9">

                <!-- Clase -->
                <div class="bg-white p-4 mb-2 rounded">


                        <span class="h4 titulosg me-3"> {{infoclase.titulos}} </span>

                        <table class="mt-3">
                            <tbody>
                                <tr>
                                    <td class="text-secondary" style="width: 120px;">Registrado Por: </td>
                                    <td>  {{infoclase.docente.nombres}} {{infoclase.docente.apellidos}} </td>
                                </tr>
                                <tr>
                                    <td class="text-secondary">Grado: </td>
                                    <td>   {{infoclase.grupos.nombregradogrupo}}  </td>
                                </tr>
                                <tr>
                                    <td class="text-secondary">Asignatura: </td>
                                    <td>  {{infoclase.asignaturas.descripcion}}  </td>
                                </tr> 
                                <tr>
                                    <td class="text-secondary">Periodo: </td>
                                    <td>   P{{infoclase.periodos.numero}} -    {{formatoFecha(infoclase.periodos.fechainicio)}} /  {{formatoFecha(infoclase.periodos.fechafin)}}      </td>
                                </tr> 
                            </tbody>
                        </table>

                    </div>

                    <div class="mt-3 p-4 bg-white rounded" v-html="infoclase.descripcion"></div>
                    
                <div class="py-3">
                    <strong> <i class="fas fa-paperclip"></i> Archivos Adjuntos <span class="badge bg-primary"> {{ infoclase.archivos.length }} </span> </strong>
                </div>

                <div class="bg-white rounded p-3" >
                    <table class="table table-hover">
                        <thead>
                            <tr>
                                <th class="text-center"> Tipo </th>
                                <th> Archivo </th>
                                <th> Tamaño </th>
                                <th>  </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="item in infoclase.archivos" :key="item.idacaanexosclase">
                                <td style="width: 30px;" class="text-center"> 
                                    <img src="/imagenes/ico/pdf.svg" class="me-3" alt="" style="height: 25px;" v-if="item.tipo == 'pdf'"> 
                                    <img src="/imagenes/ico/ppt.svg" class="me-3" alt="" style="height: 25px;" v-if="item.tipo == 'ppt' || item.tipo == 'pptx'">                                         
                                    <img src="/imagenes/ico/foto.svg" class="me-3" alt="" style="height: 25px;" v-if="item.tipo == 'jpg' || item.tipo == 'jpeg'  || item.tipo == 'png'"> 
                                    <img src="/imagenes/ico/documento.svg" class="me-3" alt="" style="height: 25px;" v-if="item.tipo == 'doc' || item.tipo == 'docx'  || item.tipo == 'txt'">
                                </td>
                                <td> <span class="text-capitalize"> {{item.descripcion}}  </span>  </td>
                                <td>
                                    <span class="text-secondary">  {{ item.peso}} Kb </span>
                                </td>
                                <td class="text-end">
                                    <a :href="archivospublicos+'/clases/'+item.archivo" target="blank" class="btn btn-outline-primary btn-sm me-2"> <i class="fas fa-download"></i> Descargar </a> 
                                    <button class="btn btn-outline-danger btn-sm " title="Eliminar"  @click="advertenciadeliminacion(item.idacaanexosclase, item.descripcion)"> <i class="far fa-trash-alt"></i>  </button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
 

                 <div class="p-2 text-secondary mb-5">
                     <strong>Creado:</strong>  {{formatoFecha(infoclase.created_at)}} <br>
                     <strong>Actualizado:</strong>  {{formatoFecha(infoclase.updated_at)}}
                 </div>
                <div>

                </div>

                <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div class="modal-dialog modal-dialog-centered">
                      <div class="modal-content">
                        <div class="modal-header">
                          <h5 class="modal-title" id="exampleModalLabel">Agregar Archivo a la Clase</h5>
                          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
        
                        <form @submit.prevent="cargararchivo" method="POST" enctype="multipart/form-data" id="formcargaarchivo">
        
                            <div class="modal-body">
                                <div class="mb-3">
                                    <label for="nommbrearchivo" class="form-label">Nombre del Archivo:</label>
                                    <input type="text" required class="form-control" id="nommbrearchivo" v-model="datosarchivo.descripcion">
                                </div>
                                <div class="mb-3">
                                    <label for="exampleFormControlTextarea1" class="form-label">Archivo:</label>
                                    <input type="file" id="archivo" name="archivo"  @change="getFile"   class="form-control" required >
        
                                    <span class="text-secondary">Tamaño máximo del archivo 10Mb</span>
        
                           
                                    <div class="alert alert-danger mt-2" v-if="alertatamanio">
                                        El tamaño del archivo excede el tamaño recomendado.
                                    </div>
                                </div>
                            </div>
                                    
                                <!-- indicador de carga -->
                                <div class="p-4" v-if="ocultarboton">
                                    <div class="h4 fw-lighter text-center mb-1">{{porcentajecarga}} %</div>
                                    <div class="progress">
                                    <div class="progress-bar" role="progressbar" :style="'width: '+porcentajecarga+'%'" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">{{porcentajecarga}}%</div>
                                    </div>
                                </div>
        
                            <div class="modal-footer">
                                <button type="button"  class="btn btn-secondary" data-bs-dismiss="modal">Cerrar</button>
                                <button type="submit" class="btn btn-primary" :disabled="ocultarboton"> <i class="fas fa-cloud-upload-alt"></i> Cargar Archivo </button>
                            </div>
                        </form>
        
        
                      </div>
                    </div>
                  </div>


        </div>

        <div class="col-md-3">
            <div class="row mb-1">
                <div class="col-md-12 mb-2" style="background-color: rgb(231, 232, 237); border-radius: 10px;"> <div class="p-3 me-2 text-capitalize"> <i class="far fa-calendar-alt"></i> Inicio:  <br> <strong>{{formatoFecha(infoclase.fechainicio)}}</strong> </div>  </div>
                <div class="col-md-12 mb-2" style="background-color: rgb(231, 232, 237); border-radius: 10px;"> <div class="p-3 me-2 text-capitalize"> <i class="far fa-calendar-alt"></i> Cierre: <br> <strong>{{formatoFecha(infoclase.fechacierre)}}</strong> </div>  </div>
                <div class="mb-2 bg-white p-3 rounded">
                    Estado: <br>
                    <span class="badge bg-success" v-if="infoclase.estadoclase == 1"> Activo </span> 
                    <span class="badge bg-light text-secondary border" v-if="infoclase.estadoclase == 2"> Inactivo </span> 
                </div>
                <div class="mb-2 bg-white p-3 rounded">
                    Tipo de Actividad:  <br>
                    <span class="badge bg-success"  v-if="infoclase.tipoactividad==1">Clase</span>
                    <span class="badge bg-warning"  v-if="infoclase.tipoactividad==2">Trabajo en Casa</span>
                    <span class="badge bg-danger text-white"  v-if="infoclase.tipoactividad==3">Actividad de Recuperación</span>
                </div>
            </div>
        </div>



    </div>
</div>
</template>

<script>

import { ref, onMounted, reactive } from 'vue'
import axios from 'axios'
import moment from 'moment'

import { useRoute, useRouter } from 'vue-router'


export default {
    props: ['id'],
    setup(props) {

        const cargando = ref(true)

        const router = useRouter()
        const route = useRoute()
        
        const infoclase = ref({
            docente: {},
            grupos: {},
            asignaturas: {},
            periodos: {},
            archivos: {}
        })

        const datosarchivo = reactive({
            idacaclase:'',
            descripcion:'',
        })
        const archivo = ref(null)
        const tamanio = ref(0)
        
        const porcentajecarga = ref(0)
        const alertatamanio = ref(false)
        const ocultarboton = ref(false)

        //Funciones

        const consultarclase = () => {
            axios.get('/api/infoclase/'+props.id).then(response => {
                infoclase.value = response.data
                datosarchivo.idacaclase = response.data.idacaclase
                cargando.value = false;
            })
        }

        const getFile = (event) => {
                
                tamanio.value = parseInt((event.target.files[0].size)/1024)
                
                if(tamanio.value > 10240){
                    alertatamanio.value = true
                    ocultarboton.value = true
                }else{            
                    alertatamanio.value = false
                    archivo.value = event.target.files[0];
                    ocultarboton.value = false
                }
            }

        const cargararchivo = () => {

            ocultarboton.value=true

                let data = new FormData();
                data.append('idclase', datosarchivo.idacaclase);
                data.append('descripcion', datosarchivo.descripcion);
                data.append('archivo',  archivo.value);
                data.append('tamanio',  tamanio.value);

                let config = {
                        header : { 'Content-Type' : 'multipart/form-data' },
                        onUploadProgress: function(progressEvent) {
                            porcentajecarga.value = Math.round((progressEvent.loaded * 100) / progressEvent.total)
                        }
                    }
                    axios.post('/api/cargararchivoclase', data, config).then(response => {        
                                        
                        if(response.data=="ok"){
                            swal("Bien!", "Información Ingresada Correctamente!", "success");   
                        }else{                        
                            swal(":(", "Ocurrrio un Error, Intenteemos nuevamente!", "error");         
                        }
                        
                        datosarchivo.descripcion = ''
                        document.getElementById("formcargaarchivo").reset();

                        consultarclase()
                        ocultarboton.value=false

                    }).catch(error => { console.log(error)})
                    

        }

        
        const editarclase = () => {
            router.push({ path: '/clases/editar/'+props.id })
        } 

        const formatoFecha = (date) => {
            return moment(date).format('MMMM D [de] YYYY');
        }

        
        const advertenciadeliminacion = (id, nombre) =>  {
            swal({
                title: "Eliminar?",
                text: "El archivo "+nombre+" será eliminado desea continuar!",
                icon: "warning",
                buttons: true,
                dangerMode: true,
                })
                .then((willDelete) => {
                if (willDelete) {                
                    eliminardocumento(id)
                } else {
                  
                }
                })
        }

        const eliminardocumento = (idarchivo) => {
            axios.get('/api/eliminararchivoclase/'+idarchivo).then( response => {
                    
               if(response.data == "ok")
               {
                swal("Bien!", "Archivo eliminado correctamente!", "success")    
               }else{
                swal(":(", "Ocurrrio un Error, Intenteemos nuevamente!", "error")    
               }

               consultarclase()

                    
            }).catch( error => { console.log(error) })
        }

        onMounted(() => {
            consultarclase()
        })

        return {
            cargando,
            infoclase,
            datosarchivo,
            archivo,
            ocultarboton,
            alertatamanio,
            porcentajecarga,

            //funciones
            formatoFecha,
            cargararchivo,
            getFile,
            advertenciadeliminacion,
            editarclase
        }

    }
}
</script>

<style>

</style>
